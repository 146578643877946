// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'VuetifyStepper',
  props: {
    // Differents "section" inside stepper
    // https://vuetifyjs.com/en/components/steppers/
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      step: 1, // Current step in "stepper"
      itemsData: {} // Global data collected from every "stepper childs"
    }
  },
  mounted() {
    // We listen the child responses
    this.$on('onResetItemsData', this.handleResetItemsData)
    this.$on('onChangeItemsData', this.handleChangeItemsData)
    this.$on('onChangeStep', this.handleChangeStep)
  },
  methods: {
    /**
     * Change the current "step" in "custom stepper"
     *
     * @param {Number} step - child step
     */
    handleChangeStep(step) {
      this.step = step > 0 ? step : 1
    },
    /**
     * Parse the "itemsData" to use in "stepper childs"
     *
     * @param {Number} step - child step
     * @param {number | string | object | array} response - child step response
     */
    handleChangeItemsData(step, response) {
      const currentStep = step > 0 ? step : 1
      const nextStep = currentStep + 1

      // Set "step" response
      this.itemsData[currentStep] = response
      this.itemsData = cloneDeep(this.itemsData)

      if (nextStep > this.items.length) {
        // Emit "onFinish" event
        this.$emit('onFinish', this.itemsData)
        this.handleResetItemsData()
      } else {
        //  We pass to next step
        this.step = nextStep
      }
    },
    /**
     * Reset the "itemsData" to use in "stepper childs"
     *
     */
    handleResetItemsData() {
      this.itemsData = {}
      this.step = 1
    }
  }
}
