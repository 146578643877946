// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Obtenemos un paquete (pack) activo de "addOns" mediante el id
 *
 * @param {String} id - UID package
 * @return {Object} - package data
 */
export async function getAddOnPackageById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/addon/package/${id}`
  })

  return result.data
}
