// Stripe
import { loadStripe } from '@stripe/stripe-js'
// Request
import { doRequest } from '@/utils/requestApi'
// Config
import { apiKey } from './config'
// Utils
import { isNil } from 'lodash'

let stripejs = null

/**
 *
 * STRIPE JS (librería para el FRONT)
 *
 */

/**
 * Cargamos la librería de StripeJS
 *
 * @return {object}
 */
export async function loadStripeJS() {
  if (isNil(stripejs)) {
    stripejs = await loadStripe(apiKey)
  }
  return stripejs
}

/**
 * Para tratar los intentos de pago que requieren
 * de una validación por parte del usuario
 *
 * @param {string} paymentIntent
 * @return {object}
 */
export async function confirmCardPayment(paymentIntent) {
  if (isNil(stripejs)) {
    stripejs = await loadStripe(apiKey)
  }

  const result = await stripejs.confirmCardPayment(paymentIntent)

  return result
}

/**
 * Almacenamos un método de pago que será usado más
 * tarde por el usuario.
 *
 * @param {string} setupIntent
 * @return {object}
 */
export async function confirmCardSetup(setupIntent) {
  if (isNil(stripejs)) {
    stripejs = await loadStripe(apiKey)
  }

  const result = await stripejs.confirmCardSetup(setupIntent)

  return result
}

/**
 *
 * SERVICIO STRIPE (Llamadas al BACK)
 *
 */

/**
 * Se crea un nuevo "customer" asociado a Bakarta
 *
 * @param {object} data - datos a enviar
 * @return {object}
 */
export async function saveCustomer(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/stripe/customer',
    data
  })

  return result.data
}

/**
 * Obtenemos datos de la próxima factura a traves del id de suscripción
 *
 * @param {string} id - id de la suscripción
 * @return {object}
 */
export async function getIncomingInvoiceBySubscriptionId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/stripe/invoice/upcoming/${id}`
  })

  return result.data
}

/**
 * Creamos un "setupIntent"
 *
 * @param {object} data - datos del "setupIntent"
 * @return {object}
 */
export async function createSetupIntent(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/stripe/setup-intent',
    data
  })

  return result.data
}

/**
 * Obtenemos los datos de un "setupIntent"
 *
 * @param {string} id - id del "setupIntent"
 * @param {object} data - datos del "setupIntent"
 * @return {object}
 */
export async function updateSetupIntentById(id, data) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/stripe/setup-intent/${id}`,
    data
  })

  return result.data
}

/**
 * Obtenemos datos de una factura
 *
 * @param {string} id - UID de la factura
 * @param {object} data - datos de la factura
 * @return {object}
 */
export async function getInvoiceById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/stripe/invoice/${id}`
  })

  return result.data
}

/**
 * Obtenemos datos de un intento de pago
 *
 * @param {string} id - UID del intento de pago
 * @param {object} data - datos de la factura
 * @return {object}
 */
export async function getPaymentIntentById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/stripe/payment-intent/${id}`
  })

  return result.data
}

/**
 * Obtenemos todos los métodos de pago de un customer
 *
 * @param {object} data - datos a enviar
 * @return {object}
 */
export async function getEveryPaymentMethods(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/stripe/payment-methods',
    data
  })
  return result.data
}

/**
 * Obtenemos los datos de un código de promoción
 *
 * @param {string} code - código a comprobar
 * @param {string} priceId - Id del precio a aplicar
 * @return {object}
 */
export async function getPromotionCode(code, priceId) {
  const result = await doRequest({
    method: 'post',
    url: `v1/stripe/promotion-code/${code}`,
    data: {
      priceId
    }
  })
  return result.data
}

/**
 * Creamos una nueva tarjeta (método de pago)
 * de un "customer" de Bakarta
 *
 * @param {string} data - datos a enviar
 * @return {object} - datos de customer con la tarjeta asociada
 */
export async function createCardForCustomer(data) {
  const { stripeCustomerId, tokenCard } = data
  const result = await doRequest({
    method: 'post',
    url: `v1/stripe/customer/card/${stripeCustomerId}`,
    data: {
      tokenCard
    }
  })

  return result.data
}
