// Components
import VuetifyStepper from '@/components/ui/VuetifyStepper'
import CheckoutStepperOne from '@/components/elements/checkout/CheckoutStepperOne'
import CheckoutStepperTwo from '@/components/elements/checkout/CheckoutStepperTwo'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'CheckoutStepper',
  components: {
    VuetifyStepper
  },
  mixins: [uiMixin],
  data() {
    return {
      // Diferent sections (items) inside "stepper"
      stepperItems: [
        {
          label: 'Pago',
          component: CheckoutStepperOne,
          componentProps: {
            id: this.$route.params.id || null
          }
        },
        {
          label: 'Resumen',
          component: CheckoutStepperTwo
        }
      ]
    }
  },
  methods: {
    /**
     * Evento lanzado cuando el Stepper ha finalizado
     *
     * @param {array} data - array de datos recolectado
     */
    async handleFinish() {
      // Redirigimos al final del proceso
      this.routerPushTo({
        path: '/checkout-finish'
      })
    }
  }
}
