// Stripe
import { loadStripeJS } from '@/services/stripe'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'StripeCreditCard',
  props: {
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      creditCard: null,
      creditCardError: null,
      stripe: null // stripeJs
    }
  },
  computed: {
    /**
     * Muestra el error actual del campo tarjeta (etiqueta)
     *
     * @return {string}
     */
    stripeCardErrorLabel() {
      if (!isNil(this.creditCardError)) {
        return this.creditCardError
      }

      if (this.errorMessages.length > 0) {
        return this.errorMessages[0]
      }

      return null
    }
  },
  watch: {
    errorMessages(value) {
      this.setStripeCardErrorCSS(!isNil(value) && value.length > 0)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Maneja la modificación de los valores
     * del "input" de la tarjeta de crédito
     *
     * @param {object} event - captura del evento
     */
    handleChangeInput(event) {
      this.creditCardError = event.error ? event.error.message : null
      this.setStripeCardErrorCSS(Boolean(this.creditCardError))
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      // Cargamos libreria de Stripe
      this.stripe = await loadStripeJS()
      // Iniciamos input
      this.initCreditCardElement()
    },
    /**
     * Crea la caja de diálogos para la introducción
     * del número de la tarjeta de crédito
     */
    initCreditCardElement() {
      const elementStripeCard = document.querySelector('#stripe-card')

      if (!isNil(elementStripeCard)) {
        const elements = this.stripe.elements()
        // Create an instance of the card Element.
        this.creditCard = elements.create('card')
        // Add an instance of the card Element into the 'stripe-card' <div>.
        this.creditCard.mount('#stripe-card')
        // Eventos cambios en input
        this.creditCard.on('change', this.handleChangeInput)
      }
    },
    /**
     * Obtiene el token del elemento (valida que la tarjeta sea correcta)
     *
     * @return {object} - resultado de la petición
     */
    async createTokenCard() {
      const tokenCard = await this.stripe.createToken(this.creditCard)
      return tokenCard
    },
    /**
     * Establece el error de CSS en el Input
     *
     * @param {boolean} error - has errors?
     * @return {string}
     */
    setStripeCardErrorCSS(error) {
      return error
        ? this.$refs.stripeCard.classList.add('StripeElement--invalid')
        : this.$refs.stripeCard.classList.remove('StripeElement--invalid')
    }
  }
}
