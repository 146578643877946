// Constants
import { ADDONS, COMPANY_TYPES_BILLING, PACKAGES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import StripeCreditCard from '@/components/ui/StripeCreditCard'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatCardNumber, formatPrice } from '@/filters'
// Services
import { getAddOnPackageById } from '@/services/addons'
import { createCardForCustomer, saveCustomer } from '@/services/stripe'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get, cloneDeep, isNil } from 'lodash'
// Validate
import { validDNI, validCIF, validNIE } from 'spain-id'

export default {
  name: 'CheckoutStepperOne',
  components: {
    FormButtons,
    GoogleMapAutocomplete,
    StripeCreditCard,
    VuetifyContentLoading
  },
  filters: { formatPrice },
  mixins: [addonsMixin, formMixin, intercomMixin, uiMixin, validationMixin],
  props: {
    id: {
      required: true,
      type: String,
      default: null
    },
    // Data from parent "stepper"
    itemsData: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    // step from "stepper"
    step: {
      required: true,
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      formFields: {
        // Price
        priceSelected: null,
        // Billing data
        billingCompanyType: null,
        billingAddress: null,
        billingExtraAddress: null,
        cif: '',
        name: null,
        stripeCustomerId: null,
        // Card
        cardId: null
      },
      formFieldsValidations: {
        priceSelected: {
          required: 'Debes seleccionar una tarifa'
        },
        billingCompanyType: {
          required: 'Campo obligatorio'
        },
        billingAddress: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        cif: {
          required: 'Campo obligatorio',
          vat: 'El número indicado no es valido para su pais de residencia'
        },
        cardId: {
          required: 'Debes indicar una tarjeta'
        },
        name: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      currentId: this.id, // Id del plan
      packages: PACKAGES,
      planData: null, // Datos del plan
      cardWasEdited: false, // "Flag" tarjeta fue editada
      processingRequest: true,
      showBothForms: true, // "Flag" visibilidad formularios
      showPaymentMethodForm: true, // "Flag" visibilidad formulario tarjeta
      companyTypes: Object.values(COMPANY_TYPES_BILLING) // Tipos de compañías
    }
  },
  computed: {
    ...mapGetters('company', ['companyData', 'trialPlan']),
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Datos de facturación (a mostrar)
     *
     * @return {object}
     */
    billingData() {
      const cardData = this.cardData
      return {
        color: this.hasBillingCompleted ? 'success' : 'error',
        companyName: this.companyData.name || '---------- ---------- --------',
        creditCard: formatCardNumber(get(cardData, 'last4', '****')),
        icon: this.hasBillingCompleted
          ? 'mdi-credit-card-check-outline'
          : 'mdi-credit-card-remove-outline'
      }
    },
    /**
     * ¿Tenemos datos de la tarjea?
     *
     * @return {object}
     */
    cardData() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.card`, {})
    },
    /**
     * CIF/NIF del usuario ya almacenado en BD
     *
     * @return {string}
     */
    currentCIFCustomer() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.cif`, null)
    },
    /**
     * Tienes todos los datos de facturación (datos y métodos de pago)
     * correctos?
     *
     * @return {boolean}
     */
    hasBillingCompleted() {
      return this.formFields.cardId && this.formFields.stripeCustomerId
    },
    /**
     * Tiene el método de pago correctamente completado
     * correctos?
     *
     * @return {boolean}
     */
    hasBillingPaymentMethodCompleted() {
      return this.formFields.cardId
    },
    /**
     * Obtenemos el array de precios del plan
     *
     * @return {Object}
     */
    planPrices() {
      const prices = get(this.planData, 'prices', [])
      return prices.map((price) => {
        const matchSuscribedPackages = this.subscribedPackages.find((subscribed) => {
          return subscribed.priceId === price.stripePriceId && this.trialDaysLeft === false
        })
        // Comprobamos la variable "active", ya que en el caso de que la suscripción,
        // estuviera desactivada, debemos permitir seleccionar el mismo precio de la
        // suscripción desactivada para poder volver a reactivarla
        const disabled = !isNil(matchSuscribedPackages) && matchSuscribedPackages.active

        return {
          disabled,
          ...price
        }
      })
    },
    /**
     * Días restante del periodo "Trial"
     *
     * @return {number}
     */
    trialDaysLeft() {
      const daysLeft = get(this.trialPlan, 'daysLeft', -1)
      return daysLeft > -1 ? daysLeft : false
    }
  },
  watch: {
    /**
     * Watch the router because we use the same component
     * to differents routes and we need that it can reload itself
     */
    async $route(to) {
      this.currentId = to.params.id
    },
    /**
     * Cuando el "id" cambia es que hemos cambiado de "plan",
     * por tanto, realizamos una nueva petición al servidor
     */
    async currentId() {
      await this.getEveryNeededData()
    },
    /**
     * Para controlar la visibilidad de los formularios de facturación
     */
    hasBillingCompleted(value) {
      // Mostramos / ocultamos formularios de facturación
      this.handleToggleBothForms(!value)
    },
    /**
     * Para controlar la visibilidad del formulario de métodos de pago
     */
    hasBillingPaymentMethodCompleted(value) {
      // Mostramos / ocultamos formulario método de pago
      this.handleTogglePaymentMethodForm(!value)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['updateCompany', 'updateConfigs']),
    /**
     * Pulsamos sobre el botón cancelar
     */
    handleCancelButton() {
      this.routerPushTo({
        path: '/plans'
      })
    },
    /**
     * Mostramos u ocultamos la vista de los formularios de "facturación"
     *
     * @param {boolean} show
     */
    handleToggleBothForms(show) {
      this.showBothForms = show
    },
    /**
     * Mostramos u ocultamos formulario "método de pago"
     *
     * @param {boolean} show
     */
    handleTogglePaymentMethodForm(show) {
      this.showPaymentMethodForm = show
    },
    /**
     * Obtenemos la descripción (HTML) que se mostrará en
     * cada una de las opciones de precios
     *
     * @param {object} price - precios con sus atributos
     * @return {string}
     */
    getPriceContentDescription(price) {
      return price.id === 'annually'
        ? `Ahorra ${formatPrice((price.amount / 10) * 2, price.currency)}`
        : 'Paga mes a mes y cancela cuando quieras.'
    },
    /**
     * Obtenemos todos los datos necesarios para cargar
     * el componente (vista) correctamente
     */
    async getEveryNeededData() {
      // Obtenemos datos de plan
      await this.getPlanData()
      // Establecemos valores de formulario
      this.setFormFieldsValues()
    },
    /**
     * Obtenemos los datos del plan
     */
    async getPlanData() {
      try {
        this.processingRequest = true

        // Obtenemos datos de plan
        const { addOnPackage } = await getAddOnPackageById(this.currentId)
        this.planData = addOnPackage

        if (isNil(this.planData) || !this.planData.active) {
          throw new Error('El identificador que intenta recuperar no existe')
        }
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
        // Lo devolvemos al listado de planes
        this.routerPushTo({
          path: '/plans'
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Establecemos todos los valores de los campos del formulario
     */
    setFormFieldsValues() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})
      // Tarifa pre-seleccionada
      const defaultPriceSelected = this.planPrices.find((price) => {
        return price.disabled === false
      })

      this.formFields.priceSelected = defaultPriceSelected.stripePriceId
      // Datos de facturación
      this.formFields.name = companyConfig.billingName || this.formFields.name
      this.formFields.billingCompanyType =
        companyConfig.billingCompanyType || this.formFields.billingCompanyType
      this.formFields.billingAddress =
        cloneDeep(companyConfig.billingAddress) || this.formFields.billingAddress
      this.formFields.billingExtraAddress =
        companyConfig.billingExtraAddress || this.formFields.billingExtraAddress
      this.formFields.cif = companyConfig.cif || this.formFields.cif
      // Datos de pago
      this.formFields.cardId = this.cardData.id || this.formFields.cardId
      // Id de Stripe
      this.formFields.stripeCustomerId = get(companyConfig, 'stripeCustomerId', null)
    },
    /**
     * Método que se dispara antes de las validaciones
     */
    async beforeSubmit() {
      // Se está mostrando el formulario de la tarjeta
      // por tanto debe pedirse nuevo token
      if (this.showPaymentMethodForm) {
        // Obtenemos token de tarjeta (ID)
        const tokenCard = await this.$refs.stripeCard.createTokenCard()
        this.formFields.cardId = get(tokenCard, 'token.id', null)
        // Marcamos "flag" para que se pueda incluir la tarjeta
        this.cardWasEdited = true
      }
    },
    /**
     * Tras la validación de los campos, se lanzan las acciones correspondientes
     */
    async afterSubmit() {
      // Separamos datos a almacenar
      const { name, cardId, stripeCustomerId, priceSelected, ...otherFields } = this.formFields

      // 1. Almacenamos / creamos "customer" en Stripe
      const customer = await this.saveBillingData({
        name,
        stripeCustomerId,
        ...otherFields
      })

      // Establecemos ID del customer de Stripe en el campo del formulario
      this.formFields.stripeCustomerId = customer.id

      // 2. Creamos método de pago (tarjeta) en Stripe

      // La tajeta fue editada (creada) por lo que debo
      // crear el registro Stripe y Firestore
      if (this.cardWasEdited) {
        await this.saveCardData(cardId, customer.id)
        // Modificamos "flag" para que deba editarse de nuevo la tarjeta
        // para que esta sea incluida de nuevo en Stripe
        this.cardWasEdited = false
      }

      // Pasamos los datos al stepper y vamos al siguiente paso
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.step, {
        priceSelected,
        planData: this.planData
      })
    },
    /**
     * Almacenamos datos de facturación en Stripe y Firebase
     *
     * @param {object}  data - datos del customer creado
     * @return {object} - datos del customer creado
     */
    async saveBillingData(data) {
      // Separamos datos a almacenar
      const { name, stripeCustomerId, ...otherFields } = data
      // Pais de facturación
      const countryCode = get(this.formFields.billingAddress, 'country_short', null)
      // 1. Almacenamos / creamos "customer" en Stripe
      const stripeData = {
        customer: {
          description: 'Cliente HORECA',
          address: {
            city: get(otherFields, 'billingAddress.locality', ''),
            country: get(otherFields, 'billingAddress.country_short', ''),
            line1: `${get(otherFields, 'billingAddress.route', '')} ${get(
              otherFields,
              'billingAddress.street_number',
              ''
            )}`,
            line2: get(otherFields, 'billingExtraAddress', ''),
            postal_code: get(otherFields, 'billingAddress.postal_code', ''),
            state: get(otherFields, 'billingAddress.administrative_area_level_2', '')
          },
          name,
          // Solo mandamos el email en la creación
          ...(!stripeCustomerId ? { email: this.userData.email } : {}),
          // Solo se manda "tax_id_data" si es Español y no es el mismo valor
          // del ya almacenado
          ...(countryCode === 'ES' && this.currentCIFCustomer !== otherFields.cif
            ? {
                tax_id_data: [
                  {
                    value: otherFields.cif,
                    type: 'es_cif'
                  }
                ]
              }
            : {})
        },
        // Solo se envía si existe (para las actualizaciones)
        ...(stripeCustomerId ? { stripeCustomerId } : {})
      }

      const { customer } = await saveCustomer(stripeData)

      if (!customer) {
        throw new Error(
          'No se ha podido crear el perfil correctamente.\
          Comuníquese con su proveedor de servicio.'
        )
      }

      // 3. Actualizamos configuraciones de "facturación" en Firebase
      await this.updateConfigs({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: {
              billingName: name,
              stripeCustomerId: customer.id,
              ...otherFields,
              // Solo mandamos el email en la creación
              ...(!stripeCustomerId ? { billingEmail: this.userData.email } : {})
            }
          }
        ]
      })

      // 4. Actualizamos nombre de la compañía en Firebase
      await this.updateCompany({
        id: this.companyData.id,
        name
      })

      // Modificamos variable de Intercom
      this.intercomUpdate({
        bakarta_facturacion_datos: true
      })

      // Devolvemos datos del "customer" creado
      return customer
    },
    /**
     * Almacenamos tarjeta en Stripe y Firebase
     *
     * @param {string} cardId
     * @param {string} stripeCustomerId
     */
    async saveCardData(cardId, stripeCustomerId) {
      // 1. Asociamos tarjeta al usuario en Stripe
      const customer = await createCardForCustomer({
        stripeCustomerId,
        tokenCard: cardId
      })

      if (!customer) {
        throw new Error(
          'No se ha podido almacenar correctamente el método de pago. \
          Comuníquese con su proveedor de servicio.'
        )
      }

      // 2. Almacenamos en Firebase (configuraciones)
      await this.updateConfigs({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: {
              card: {
                id: get(customer, 'sources.data[0].id', null),
                brand: get(customer, 'sources.data[0].brand', null),
                exp_month: get(customer, 'sources.data[0].exp_month', null),
                exp_year: get(customer, 'sources.data[0].exp_year', null),
                last4: get(customer, 'sources.data[0].last4', null)
              }
            }
          }
        ]
      })

      // Modificamos variable de Intercom
      this.intercomUpdate({ bakarta_facturacion_metodo_pago: true })
    }
  },
  // Validations with Vuelidate
  validations() {
    const codeCountry = get(this.formFields.billingAddress, 'country_short', null)

    return {
      formFields: {
        // Tipo de tarifa
        priceSelected: {
          required
        },
        // Campos de facturación
        name: {
          required
        },
        billingCompanyType: {
          required
        },
        billingAddress: {
          required
        },
        billingExtraAddress: {},
        cif:
          // Solo validamos a los españoles
          codeCountry === 'ES'
            ? {
                required,
                vat: (value) => {
                  // DNI, CIF or NIE
                  return validDNI(value) || validCIF(value) || validNIE(value)
                }
              }
            : {},
        stripeCustomerId: {},
        // Campos de métodod de pago
        cardId: {
          required
        }
      }
    }
  }
}
