// Mixins
import dataLayerMixin from '@/mixins/dataLayerMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  confirmCardPayment,
  confirmCardSetup,
  getEveryPaymentMethods,
  getPromotionCode,
  updateSetupIntentById,
  getInvoiceById,
  getPaymentIntentById
} from '@/services/stripe'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Filters
import { formatPrice } from '@/filters'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CheckoutStepperTwo',
  filters: { formatPrice },
  mixins: [dataLayerMixin, intercomMixin, uiMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    // Data from parent "stepper"
    step: {
      required: true,
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // Datos del plan
      planData: null,
      // Coupon
      promotionCode: {},
      promotionCodeInput: null,
      // Others
      formProcessing: false,
      priceSelected: null,
      // "Flag" formulario cupones
      showCouponForm: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'subscribedPackages']),
    ...mapGetters('company', ['companyData', 'companyDataConfig', 'trialPlan']),
    /**
     * Fue aplicado un descuento
     *
     * @return {boolean}
     */
    appliedPromotionCode() {
      return Boolean(get(this.promotionCode, 'id', false))
    },
    /**
     * Obtenemos los datos del precio seleccionado
     *
     * @return {Object}
     */
    currentAddOnPrice() {
      const addOnPrices = get(this.planData, 'prices', [])

      return addOnPrices.find((price) => {
        return price.stripePriceId === this.priceSelected
      })
    },
    /**
     * Resumen del pedido
     *
     * @return {object}
     */
    orderSummary() {
      // Precio del paquete
      let subtotal = this.priceSelectedData.amount
      // Resumen del pedido
      const summary = {
        items: [
          {
            label: 'Subtotal',
            value: formatPrice(subtotal, this.priceSelectedData.currency)
          }
        ],
        total: {
          label: 'Total',
          value: 0 // Al final de la función colocamos el valor real
        }
      }
      // Incluimos el saldo del wallet
      const wallet = get(this.companyData, 'wallet', 0)

      // Incluimos el descuento por cupón
      if (get(this.promotionCode, 'id', false)) {
        summary.items.push({
          label: this.promotionCode.name,
          value: this.promotionCode.amount
            ? `- ${formatPrice(this.promotionCode.amount, this.promotionCode.currency)}`
            : `- ${this.promotionCode.percent}%`
        })

        // Aplicamos el descuesto al subtotal
        subtotal = this.promotionCode.amount
          ? subtotal - this.promotionCode.amount
          : subtotal * ((100 - this.promotionCode.percent) / 100)
      }

      // Descuento por saldo
      if (!isNil(wallet) && wallet > 0) {
        const totalBalanceDiscount = wallet >= subtotal ? subtotal : wallet

        summary.items.push({
          label: 'Saldo en Bakarta',
          value: `- ${formatPrice(totalBalanceDiscount, 'EUR')}`
        })
        // Aplicamos el descuesto al subtotal
        subtotal -= totalBalanceDiscount
      }

      // Colocamos el total (con el descuento)
      summary.total.value = `${formatPrice(subtotal, this.priceSelectedData.currency, true)} / ${
        this.priceSelectedData.name
      }`

      return summary
    },
    /**
     * Devuelve los datos del plan seleccionado
     *
     * @return {object}
     */
    priceSelectedData() {
      return this.planData.prices.find((price) => {
        return price.stripePriceId === this.priceSelected
      })
    },
    /**
     * Obtenemos la suscripción que corresponde con el
     * plan seleccionado
     *
     * @return {object | null}
     */
    subscriptionMatchingPlan() {
      return Array.isArray(this.subscribedPackages) && this.subscribedPackages.length > 0
        ? this.subscribedPackages.find((subscribed) => {
            return subscribed.packageId === this.planData.id
          })
        : null
    },
    /**
     * Días restante del periodo "Trial"
     *
     * @return {number}
     */
    trialDaysLeft() {
      const daysLeft = get(this.trialPlan, 'daysLeft', -1)
      return daysLeft > -1 ? daysLeft : false
    }
  },
  watch: {
    itemsData(value) {
      // Establecemos los valores del paso anterior
      this.priceSelected = get(value, `[${this.step - 1}].priceSelected`, null)
      this.planData = get(value, `[${this.step - 1}].planData`, null)
    }
  },
  methods: {
    ...mapActions('place', [
      'createSubscriptionInPlace',
      'updateSubscriptionInPlace',
      'reactivateSubscriptionInPlace',
      'cancelSubscriptionInPlace'
    ]),
    /**
     * Pulsamos sobre el botón cancelar
     */
    handleCancelButton() {
      // Paso anterior
      this.$parent.$parent.$parent.$emit('onChangeStep', this.step - 1)
    },
    /**
     * Cuando se pulsa en el botón de "Comprobar" código
     */
    async handleClickPromotionCode() {
      try {
        // Loading
        this.formProcessing = true
        // Launch request
        const promotionCode = await getPromotionCode(this.promotionCodeInput, this.priceSelected)

        if (!promotionCode) {
          throw new Error('Error en la comprobación del código')
        }

        // Datos del código de promoción
        this.promotionCode = {
          amount: Number.parseInt(get(promotionCode, 'coupon.amount_off', 0), 10) / 100,
          currency: get(promotionCode, 'coupon.currency', 'EUR'),
          id: get(promotionCode, 'id', null),
          name: get(promotionCode, 'coupon.name', null),
          percent: get(promotionCode, 'coupon.percent_off', null),
          trial: get(promotionCode, 'coupon.metadata.trialPeriod', null)
        }

        if (!this.promotionCode.id) {
          throw new Error('No se pudo obtener un código de promoción válido')
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Cuando hacemos click sobre el botón de acción
     */
    async handleClickActionButton() {
      try {
        // Loading
        this.formProcessing = true

        // ¿Es una reactivación?
        const isReactivation =
          !isNil(this.subscriptionMatchingPlan) &&
          this.subscriptionMatchingPlan.active === false &&
          this.subscriptionMatchingPlan.priceId === this.priceSelected

        if (isReactivation) {
          // Reactivamos suscripción cancelada
          await this.reactivateAddOnToPlace()
        } else {
          // Añadimos el plan al establecimiento
          await this.setSubscriptionToPlace()
        }

        // Modificamos variables de DataLayer
        await this.dataLayerSetVariables({ bakarta_plan: this.planData.id })

        // Modificamos variable de Intercom
        this.intercomUpdate({ bakarta_plan: this.planData.id })

        // Pasamos los datos al stepper y vamos al siguiente paso
        this.$parent.$parent.$parent.$emit('onChangeItemsData', this.step, null)
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Proceso de suscripcion
     */
    async setSubscriptionToPlace() {
      const subscriptionData = {
        stripe: {
          // Se envía en creación
          ...(isNil(this.subscriptionMatchingPlan)
            ? {
                stripeCustomerId: this.companyDataConfig.stripeCustomerId
              }
            : {}),
          stripePriceId: this.currentAddOnPrice.stripePriceId,
          // Se envía en actualización
          ...(!isNil(this.subscriptionMatchingPlan)
            ? {
                stripeSubscriptionId: this.subscriptionMatchingPlan.stripeSubscriptionId
              }
            : {}),
          ...(this.promotionCode.id
            ? {
                promotionCode: this.promotionCode.id
              }
            : {}),
          ...(this.promotionCode.trial
            ? {
                trialPeriod: this.promotionCode.trial
              }
            : {})
        },
        subscription: {
          // Se envía en actualización
          ...(!isNil(this.subscriptionMatchingPlan)
            ? {
                id: this.subscriptionMatchingPlan.id
              }
            : {}),
          packageId: this.planData.id,
          companyId: this.companyData.id,
          placeId: this.placeData.id,
          data: {
            amount: this.currentAddOnPrice.amount,
            currency: 'EUR',
            period: this.currentAddOnPrice.period,
            priceId: this.currentAddOnPrice.stripePriceId
          }
        }
      }

      // Ejecutamos la petición
      const result = isNil(get(subscriptionData, 'subscription.id', null))
        ? await this.createSubscriptionInPlace(subscriptionData)
        : await this.updateSubscriptionInPlace(subscriptionData)

      try {
        // Futuros cobros
        const pendingSetupIntent = get(result, 'pending_setup_intent', null)
        // Cobros en el momento
        const paymentIntent = get(result, 'latest_invoice.payment_intent', null)
        // Factura pendientes
        const latestInvoice = get(result, 'latest_invoice', null)

        // Distintas formas de invocar 3D Secure
        if (
          !isNil(paymentIntent) &&
          typeof paymentIntent === 'object' &&
          result.status === 'incomplete'
        ) {
          // Pagos que se realizan en el acto
          const { error } = await confirmCardPayment(paymentIntent.client_secret)

          if (!isNil(error)) {
            throw new Error(error.message)
          }
        } else if (
          !isNil(latestInvoice) &&
          typeof latestInvoice === 'string' &&
          result.status === 'past_due'
        ) {
          // Pago de una factura pendiente (cuando pasamos de un plan inferior a uno superior)
          // 1. Obtenemos datos de la factura
          const invoiceRequest = await getInvoiceById(latestInvoice)

          if (!invoiceRequest) {
            throw new Error('No se ha podido obtener datos de la factura a pagar.')
          }
          // 2. Obtenemos los datos del intento de pago
          const paymentIntentRequest = await getPaymentIntentById(
            invoiceRequest.invoice.payment_intent
          )
          if (!paymentIntentRequest) {
            throw new Error('No se ha podido obtener datos del intento de pago.')
          }

          // 3. Realizamos la comprobación 3D Secure con los datos del paymentIntent
          const { error } = await confirmCardPayment(paymentIntentRequest.client_secret)
          if (!isNil(error)) {
            throw new Error(error.message)
          }
        } else if (result.status === 'trialing' && !isNil(pendingSetupIntent)) {
          // Pago que se realizará a futurible (se necesita cargar el método de pago y asegurarlo)

          // 1. Obtenemos método de pago del customer
          const currentCard = await this.getDefaultPaymentMethod(
            this.companyDataConfig.stripeCustomerId,
            this.companyDataConfig.card.id
          )

          // 2. Modificamos el "setupIntent", incluyendo en este el método de pago
          const setupIntentRequest = await updateSetupIntentById(pendingSetupIntent, {
            payment_method: currentCard.id
          })

          if (!setupIntentRequest) {
            throw new Error('No hemos podido realizar el cobro con la tarjeta indicada.')
          }

          // 3. Verificamos que la tarjeta del usuario está "confirmada" por este
          // para el cobro futuro de la suscripción, usando 3D Secure
          const { error } = await confirmCardSetup(setupIntentRequest.client_secret)

          if (!isNil(error)) {
            throw new Error(error.message)
          }
        }
      } catch (error) {
        if (result.status === 'incomplete') {
          // await this.cancelSubscriptionInPlace({
          //   canceledFor: 'Cancelada por el usuario',
          //   cancelInmediatly: false,
          //   placeId: this.placeData.id,
          //   stripeSubscriptionId: result.id,
          //   subscriptionId: this.subscriptionId
          // })
        }
        throw new Error(error.message)
      }
    },

    /**
     * Obtenemos el método de pago por defecto (en Stripe)ç
     * del usuario registrado en BD
     *
     * @param {string} customerId - UID del customer en BD
     * @param {string} cardId - UID de la tarjeta en BD
     */
    async getDefaultPaymentMethod(customerId, cardId) {
      if (isNil(customerId) || isNil(cardId)) {
        return null
      }

      // Obtenemos los métodos de pago del customer
      const paymentMethodsRequest = await getEveryPaymentMethods({ customer: customerId })

      if (!paymentMethodsRequest || paymentMethodsRequest.length === 0) {
        throw new Error('No hemos podido obtener los métodos de pago de su usuario.')
      }

      // De los métodos de pago obtenidos, nos quedamos con la tarjeta
      // actualmente configurada en Bakarta
      const currentCard = paymentMethodsRequest.data.find((card) => card.id === cardId)

      if (!currentCard) {
        throw new Error('No posees ninguna tarjeta configurada.')
      }

      return currentCard
    },

    /**
     * Reactivamos una suscripción cancelada
     */
    async reactivateAddOnToPlace() {
      // Reactivate subcription
      await this.reactivateSubscriptionInPlace({
        placeId: this.placeData.id,
        subscriptionId: this.subscriptionMatchingPlan.id,
        stripeSubscriptionId: this.subscriptionMatchingPlan.stripeSubscriptionId
      })
    }
  }
}
