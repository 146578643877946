// Utils
import { timeout } from '@/utils'

export default {
  methods: {
    /**
     * Existe variable "dataLayer"?
     *
     * @return {boolean}
     */
    dataLayerIsAvailable() {
      return window.dataLayer && Array.isArray(window.dataLayer)
    },
    /**
     * Actualizamos datos del dataLayer
     *
     * @param {object} params - parámetros que deseamos almacenar
     */
    async dataLayerPush(params) {
      if (!this.dataLayerIsAvailable()) return
      window.dataLayer.push(params)

      await timeout(1)

      // Lanzamos evento de escucha
      window.dataLayer.push({
        event: 'dataLayer_initLoad'
      })
    },
    /**
     * Actualizamos variables existentes en dataLayer
     *
     * @param {object} params - parámetros que deseamos modificar
     */
    async dataLayerSetVariables(params) {
      if (!this.dataLayerIsAvailable()) return
      // Para poder hacer "set" de variables ya existentes
      // debemos lanzar push con la función "set" de dataLayer
      window.dataLayer.push(function () {
        Object.entries(params).map((param) => {
          return this.set(param[0], param[1])
        })
      })

      await timeout(1)

      // Lanzamos evento de escucha
      window.dataLayer.push({
        event: 'dataLayer_initLoad'
      })
    }
  }
}
